/* .nav-menu a:hover{
    background-color: #056488;
    color: white;
    border-radius: 5px;

} */

.nav-menu .active{
    color: var(--three-color);
    border-bottom: 2px solid var(--three-color);
}

/* .nav-menu a{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #333;
    text-decoration: none;
    position: relative;
    padding: 3px;
} */

/* .nav-menu a {
    position: relative;
    text-decoration: none;
    color: black;
} */

/* .nav-menu a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px; 
    width: 0;
    background-color: transparent; 
    transition: width 0.3s ease;
    transition: width 0.3s ease, background-color 0.3s ease;
} */

/* .nav-menu a:hover::after {
    width: 100%;
    background-color: #333;
} */

/* .nav-menu a:hover::after,
.nav-menu .active:hover::after { 
    width: 100%;
    background-color: #00a5b9; 
} */
 
/* .nav-menu .active::after { 
    width: 100%;
    background-color: #00a5b9; 
} */




/* @keyframes fadeIn {
    0% {
      opacity: 0.1;
      transform: translateY(-70px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

.Header-p1 {
    color: black;
    text-align: center;
    width: 100%;
}

.Sticky-p1 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    animation: fadeIn 0.6s ease-in !important;
  } */