.bg-img-s{
    background-image: url(../../Images/Group-336.png) !important;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 991px) {
    .bg-img-s{
        background-image: url(../../Images/Group-336.png) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
  }

@media only screen and (max-width: 576px) {
    .bg-img-s{
        background-image: url(../../Images/Group-336.png) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
  }