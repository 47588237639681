.about-img{
    background-image: url(../../Images/Group-336.png);
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 991px) {
    .about-img{
        background-image: url(../../Images/Group-336.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
  }

@media only screen and (max-width: 576px) {
    .about-img{
        background-image: url(../../Images/Group-336.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
  }