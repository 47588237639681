.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    gap: 50px;
    width: 100%;
    background: linear-gradient(to top, transparent 25%, var(--second-color) 50%) !important;
    
  }
  
  .contact-page .center-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .center-box {
    position: relative;
    margin: 0 auto;
    width: fit-content;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    /* margin: 10px 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    transition: height 5s ease-in-out;
    /* margin-bottom: 150px; */
    background-color: white;
  }

